<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', localTaxForm)">
        <b-card-code>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                label="Languages"
                label-for="mc-destination"
              >
                <v-select
                  v-model="localTaxForm.selectedLanguages"
                  class="language"
                  :disabled="true"
                  placeholder="Select Languages"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :options="localTaxForm.selectedLanguages"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="Destination" label-for="mc-destination">
                <validation-provider #default="{ errors }" name="Destination">
                  <b-form-input
                    v-model="destination"
                    class="destination"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Destination"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="(item, index) in localTaxForm.localTaxLanguage"
              :key="index"
              cols="12"
              md="6"
            >
              <b-form-group
                :label="`${item.languageName}`"
                label-for="mc-destination"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="`${item.languageName} Content`"
                  rules="required|pattern|max:100"
                >
                  <b-form-input
                    v-model="item.content"
                    class="destination"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="`${item.languageName} content`"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
                <!-- submit button -->
            <b-col md="12" class="demo-inline-spacing">
              <b-button :disabled="loading" variant="primary" type="submit">
                Save
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
              <b-button variant="secondary" :to="{ name: 'local-tax', query: { page: $route.query.page, rows: $route.query.rows } }">
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import languageService from "@/services/language/language.service";
import currencyService from "@/services/currency/currency.service";
// import localTaxService from '@/services/local-tax/localTax.service'
import { resConditionCheck } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@/@core/utils/validations/validations";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCardCode,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BButton
  },

  props: {
    localTaxDetails: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      localTaxForm: {
        selectedLanguages: [],
        localTaxLanguage: []
        // localTaxCurrency: []
      },
      staticLanguageObject: {
        id: "",
        content: "",
        language_id: "",
        local_tax_id: Number(this.$route.params.id),
        languageName: ""
      },
      staticCurrencyObject: {
        id: "",
        value: "",
        local_tax_id: Number(this.$route.params.id),
        currency_id: "",
        symbol: ""
      },
      totalCurrencies: [],
      loading: false,
      required,
      destination: ""
    };
  },

  async mounted() {
    this.destination = localStorage.getItem("resort_name");
    await this.getLanguageDropdown();
    await this.getCurrencies();
    for (
      let i = 0;
      i < this.localTaxForm.selectedLanguages.length;
      i += 1
    ) {
      if(this.localTaxDetails.localTaxLanguages && this.localTaxDetails.localTaxLanguages.length) {
        this.localTaxForm.localTaxLanguage.push({
          ...this.staticLanguageObject,
          language_id: this.localTaxForm.selectedLanguages[i].value,
          languageName: this.localTaxForm.selectedLanguages[i].label
        });

        this.localTaxForm.localTaxLanguage[i].content = this.localTaxDetails.localTaxLanguages[i] && this.localTaxDetails.localTaxLanguages[i].content ? this.localTaxDetails.localTaxLanguages[i].content : '';
        this.localTaxForm.localTaxLanguage[i].local_tax_id = this.$route.params.id ? this.$route.params.id : '';
        this.localTaxForm.localTaxLanguage[i].id = this.localTaxDetails.localTaxLanguages[i] && this.localTaxDetails.localTaxLanguages[i].id ? this.localTaxDetails.localTaxLanguages[i].id : '';
      } else {
        this.localTaxForm.localTaxLanguage.push({
          ...this.staticLanguageObject,
          language_id: this.localTaxForm.selectedLanguages[i].value,
          languageName: this.localTaxForm.selectedLanguages[i].label
        });
      }
    }
  },

  methods: {
    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.localTaxForm.selectedLanguages = res.data.data.map(e => ({
            label: e.name,
            value: e.id
          }));

          // for (let i = 0; i < res.data.data.length; i += 1) {
          //   this.localTaxForm.localTaxLanguage.push({ ...this.staticLanguageObject, language_id: res.data.data[i].id, languageName: res.data.data[i].name })
          // }
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          valid = false;
        }
      });
      return valid;
    },

    // async editLocalTax() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       this.loading = true
    //       localTaxService.editLocalTax(this.$route.params.id, this.localTaxForm).then(res => {
    //         if (resConditionCheck(res.status) && res.data.data) {
    //           this.loading = false
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: res.data.message,
    //               icon: "CheckIcon",
    //               variant: "success",
    //             },
    //           })
    //         }
    //       }).catch(error => {
    //         this.loading = false
    //         const errorData = errorResponseHandler(error)
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: errorData,
    //             icon: "X-CircleIcon",
    //             variant: "danger",
    //           },
    //         });
    //       })
    //     }
    //   })
    // },

    async getCurrencies() {
      try {
        const res = await currencyService.getCurrencyDropDown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.totalCurrencies = res.data.data.map(e => ({
            label: e.symbol,
            value: e.id
          }));
          // for (let i = 0; i < res.data.data.length; i += 1) {
          //   this.localTaxForm.localTaxCurrency.push({ ...this.staticCurrencyObject, currency_id: res.data.data[i].id, symbol: res.data.data[i].symbol })
          // }
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
