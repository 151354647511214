<template>
  <div>
    <LocalTaxAdd
      ref="simpleRules"
      :key="$route.fullPath"
      :is-loaded="isLoaded"
      :local-tax-details="localTaxDetails"
      @on-Submit="editLocalTax"
    />
  </div>
</template>

<script>
import localTaxService from "@/services/local-tax/localTax.service";
import { resConditionCheck } from "@/helpers";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LocalTaxAdd from "./LocalTaxAdd.vue";

export default {
  components: { LocalTaxAdd },
  data() {
    return {
      isLoaded: false,
      localTaxDetails: {}
    };
  },

  async mounted() {
    await this.getLocalTaxById(this.$route.params.id);
  },
  methods: {
    async getLocalTaxById(id) {
      try {
        const res = await localTaxService.getLocalTaxById(id);
        if (resConditionCheck(res.status) && res.data.data) {
          this.localTaxDetails = res.data.data;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async editLocalTax(localTaxForm) {
      const valid = await this.$refs.simpleRules.validationForm();

      const { selectedLanguages, ...updatedLocalTaxForm } = localTaxForm;
      if (valid) {
        this.$refs.simpleRules.loading = true;
        localTaxService
          .editLocalTax(this.$route.params.id, updatedLocalTaxForm)
          .then(res => {
            if (resConditionCheck(res.status) && res.data.message) {
              this.$refs.simpleRules.loading = false;
              this.$router.push({ name: "local-tax", query: { page: this.$route.query.page, rows: this.$route.query.rows } });
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.simpleRules.loading = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    }
  }
};
</script>
